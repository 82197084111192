import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import * as Sentry from "@sentry/react";

const SENTRY_LIVE = process.env.SENTRY_LIVE || "false";

if (!window.location.origin.includes("localhost") && SENTRY_LIVE === "true") {
  Sentry.init({
    dsn: "https://748dc09481d5068c27a8051120b1a6a9@o4507695388098560.ingest.us.sentry.io/4507736094408704",
    integrations: [],
    environment: window.location.origin.includes("dev") ? "dev" : "prod",
    beforeSend: function (event) {
      window.smartlook &&
        window.smartlook("track", "Sentry error", {
          url: `${process.env.REACT_APP_SENTRY_DOMAIN}/issues/?query=id%3A${event.event_id}`,
        });

      return event;
    },
  });

  window.Sentry = Sentry;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
